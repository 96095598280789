// require("dotenv/config");
function createMetaGoogleBots() {
  const metaNew = document.createElement("meta");
  metaNew.setAttribute("name", "googlebot");
  metaNew.setAttribute("content", `${process.env.INDEX_AND_FOLLOW}`);
  document.head.appendChild(metaNew);
}

function createMetaRobots() {
  const metaNew = document.createElement("meta");
  metaNew.setAttribute("name", "robots");
  metaNew.setAttribute(
    "content",
    `${process.env.INDEX_AND_FOLLOW} max-image-preview:large, max-snippet:-1, max-video-preview:-1`
  );
  document.head.appendChild(metaNew);
}



function GoogleTagManager() {
    // console.log('condition true');
  // if (window.location.href.includes("https://quokkalabs.com/")) {
  const metaNew = document.createElement("script");
  metaNew.textContent = `(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); 
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.defer = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-W2P77FP');` 

  document.head.appendChild(metaNew);
}






createMetaGoogleBots();
createMetaRobots();
if (process.env.NODE_ENV === 'production') {
  GoogleTagManager();
  window.GoogleTagManager = GoogleTagManager
}

window.createMetaGoogleBots = createMetaGoogleBots
window.createMetaRobots = createMetaRobots